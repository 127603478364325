<template>
  <div @click.stop v-if="session" class="d-flex flex-column flex-grow-1">
    <ul v-if="programId === null" class="nav nav-tabs flex-shrink-0 pt-3 px-3">
      <li class="nav-item">
        <a
          @click="activePanel = 'session'"
          :class="activePanel === 'session' ? 'active' : ''"
          class="nav-link"
          role="button"
        >Overview</a>
      </li>
      <li class="nav-item">
        <a
          @click="activePanel = 'comment'"
          :class="{
            active: activePanel === 'comment',
            disabled: activeExercise,
          }"
          class="nav-link"
          role="button"
        >Comments</a>
      </li>
    </ul>

    <div v-show="activePanel === 'session'">
      <SessionEditorOverview
        @sessionEditorOverviewUpdate="$emit('sessionEditorUpdate', $event)"
        v-if="!activeExercise"
        :session="session"
        :programId="programId"
        class="m-3"
      ></SessionEditorOverview>

      <div v-if="activeExercise === null" class="border-top my-3 px-3">
        <div class="row gx-3 my-3">
          <div class="col-4">
            <SubmitButton
              @click="addItem('section')"
              :text="'Add Section'"
              :isLoading="isAddingSection"
              :disabled="isAddingSection || isAddingExercise || isAddingCircuit"
              class="btn btn-sm btn-outline-primary w-100"
            ></SubmitButton>
          </div>
          <div class="col-4">
            <SubmitButton
              @click="addItem('exercise')"
              :text="'Add Exercise'"
              :isLoading="isAddingExercise"
              :disabled="isAddingSection || isAddingExercise || isAddingCircuit"
              class="btn btn-sm btn-outline-primary w-100"
            ></SubmitButton>
          </div>
          <div class="col-4">
            <SubmitButton
              @click="addItem('circuit')"
              :text="'Add Circuit'"
              :isLoading="isAddingCircuit"
              :disabled="isAddingSection || isAddingExercise || isAddingCircuit"
              class="btn btn-sm btn-outline-primary w-100"
            ></SubmitButton>
          </div>
        </div>

        <draggable
          @end="reorderItems"
          v-if="session.exercises.length"
          v-model="session.exercises"
          delay="400"
          delayOnTouchOnly="true"
          class="list-group mb-3"
        >
          <div
            v-for="(exercise, exerciseIndex) in session.exercises"
            :key="`key-${exerciseIndex}-${exercise.exerciseId}`"
            class="list-group-item"
            role="button"
          >
            <SessionEditorItem
              @sessionEditorItemSelect="activeExercise = exercise"
              @sessionEditorItemDelete="deleteItem"
              @sessionEditorItemSuperset="supersetItem"
              :session="session"
              :exercise="exercise"
              :athleteId="athleteId"
            ></SessionEditorItem>
          </div>
        </draggable>

        <div v-else class="my-5 text-center">
          <span class="small fst-italicx text-muted">No activity yet. Start adding one.</span>
        </div>
      </div>
    </div>

    <Comment
      v-show="activePanel === 'comment'"
      :session="session"
      :class="activePanel === 'comment' ? 'd-flex flex-column flex-grow-1' : ''"
      class="comment"
    ></Comment>

    <transition name="slide-left">
      <div v-if="activeExercise" class="position-relative">
        <div class="p-3 border-bottom bg-white position-sticky back-container">
          <IconButton
            @click="activeExercise = null"
            :icon="'chevron-left'"
            :text="'Back'"
            class="btn btn-primary"
          ></IconButton>
        </div>
        <SessionEditorItemDetails
          @sessionEditorItemDetailsUpdate="updateItem"
          :session="session"
          :exercise="activeExercise"
          :athleteId="athleteId"
        ></SessionEditorItemDetails>
      </div>
    </transition>
  </div>
</template>

<script>
import draggable from 'vuedraggable';

export default {
  name: 'SessionEditor',
  components: {
    draggable,
    SessionEditorOverview: () => import('./SessionEditorOverview.vue'),
    SessionEditorItem: () => import('./SessionEditorItem.vue'),
    SessionEditorItemDetails: () => import('./SessionEditorItemDetails.vue'),
    IconButton: () => import('@/components/button/IconButton'),
    SubmitButton: () => import('@/components/button/SubmitButton'),
    Comment: () => import('@/components/comment/Comment'),
  },
  props: {
    session: {
      type: Object,
      required: true,
    },
    programId: {
      type: String,
      default: null,
    },
    athleteId: {
      type: String,
      default: null,
    },
  },
  watch: {
    session() {
      this.isAddingSection = false;
      this.isAddingExercise = false;
      this.isAddingCircuit = false;
    },
    activeExercise() {
      this.$emit('sessionEditorShowItemDetails');
    },
  },
  methods: {
    addItem(itemType) {
      if (itemType === 'section') {
        this.isAddingSection = true;
      } else if (itemType === 'exercise') {
        this.isAddingExercise = true;
      } else if (itemType === 'circuit') {
        this.isAddingCircuit = true;
      }
      this.$emit('sessionEditorAddItem', itemType);
    },
    updateItem(item) {
      this.$emit('sessionEditorUpdateItem', item);
    },
    deleteItem(itemId) {
      this.$emit('sessionEditorDeleteItem', itemId);
    },
    supersetItem(itemPayload) {
      this.$emit('sessionEditorSupersetItem', itemPayload);
    },
    reorderItems(e) {
      const { oldIndex, newIndex } = e;
      if (oldIndex === newIndex) return;
      const itemIds = this.session.exercises.map((exercise) => exercise.exerciseId);
      this.$emit('sessionEditorReorderItems', itemIds);
    },
  },
  data() {
    return {
      activePanel: 'session',
      activeExercise: null,
      isAddingSection: false,
      isAddingExercise: false,
      isAddingCircuit: false,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/vars.scss";

.nav.nav-tabs {
  background-color: $secondarySystemBackground;
}

.back-container {
  top: 0;
  z-index: 200;
}
</style>
